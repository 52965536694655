<template>
    <div>
        消息
    </div>
    
</template>
<script>
export default {
    data() {
        return {
            
        };
    },
};
</script>
<style>

</style>
